import {React, useRef} from "react";
import "../../../index.css";
import useUpdateDomTitle from "../../../../utils/updateDomTitle";
import { useAdjustBackdropHeight } from "../../../backdrop";
import useScroller from "../../../../utils/Scroller";
import { isLight } from "../../../../utils/Color";
import linkFixer from "../../../../utils/LinkFixer";

let Thanks = ({qrData}) => {

    const data = qrData


    const ignoredColors = ["#D97150", "#BCA67C", "#14B4DE"];
    const light = isLight(data.primaryColor, ignoredColors);

    useScroller(data);

    useUpdateDomTitle(data?.title,data?.primaryColor);

    let backdropElement = useRef(null);
    let backdropEndElement = useRef(null);
    let backdropEndFallbackElement = useRef(null);
    useAdjustBackdropHeight(backdropElement, backdropEndElement, backdropEndFallbackElement);

    return (
        <div className="flex flex-col justify-center items-center relative">
            <div className="flex items-center justify-center relative w-full h-full -z-10 overflow-x-clip">
                <div ref={backdropElement} className="qr-backdrop" style={{'--bg-color': data.primaryColor || "#97000F"}}>
                <svg width="375" height="526" viewBox="0 0 375 526" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 0H375V506C375 506 293.5 461 187.5 506C81.5 551 0 506 0 506V0Z" fill={'var(--bg-color)'}/>
                </svg>
                </div>
            </div>
            <div ref={backdropEndFallbackElement} ></div>
            <div
                className="flex flex-col items-center justify-center mt-[45px] xs:mt-[54px] w-full max-w-[600px] px-[16px]"
                >
                <h1 className="text-white text-xl xs:text-2xl font-bold mb-[14px] xs:mb-[28px]">Feedback Received</h1>
                <div ref={backdropEndElement} className="w-full bg-white flex flex-col justify-center items-center p-[15px] rounded-[4px] mb-[10px] xs:mb-[20px] shadow-[1px_6px_30px_rgba(0,31,14,0.05)]">
                <img ref={backdropEndElement} src="/images/images/new/thanks.svg" className="w-full rounded-[4px] mb-6" alt=""/>
                <p className="text-xl xs:text-2xl font-bold mb-3" style={{fontFamily: `'${data?.font_title}'`}}>Thank You</p>
                <p className="text-base xs:text-lg mb-9 text-[#767C83]" style={{fontFamily: `'${data?.font_text}'`}}>Your feedback has been submitted. Thank you for taking the time to share your opinion</p>
                <div 
                    className="w-full flex justify-center items-center rounded-[4px] py-[14px] cursor-pointer hover:opacity-80"
                    style={{backgroundColor: data?.primaryColor ? data.primaryColor : "#97000F"}}
                    onClick={() => {
                    if (data?.website === "www.bustour.com" || data?.website === "www.beststockphotos.com" || data.website === "www.downtowntrolley.com" || data.website === "www.venicesnacks.com") {
                    //do noting :D
                    } else {
                        window.open(linkFixer(data.website), "_blank");
                    }
                    }}
                >
                    <p className="text-lg xs:text-xl text-white" style={{color: light ? "#000000" : "#ffffff"}} >Visit our website</p>
                </div>
                </div>
            </div>
        </div>

    )
}
export default Thanks;

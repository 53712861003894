import {React, useEffect, useState} from "react";
import useUpdateDomTitle from "../../../../utils/updateDomTitle";
import WelcomeScreen from "../../../common/WelcomeScreen";
import ShareOrCopy from "../../../../utils/share";
import useScroller from "../../../../utils/Scroller";
import { isLight } from "../../../../utils/Color";
import useLanguage from "../../../../utils/useLanguage";
import AOS from "aos";
import "aos/dist/aos.css";
import { isAndroid, isIOS} from "mobile-device-detect";


let FacebookQci = ({ qrData }) => {
  const data = qrData;
  const t = useLanguage();
  useUpdateDomTitle(data?.title, data?.primaryColor);
  const [welcomeScreen, showWelcomeScreen] = useState(false);
  const [newFbUrl, setNewFbUrl] = useState("");
  const [isChromeBrowser, setIsChromeBrowser] = useState(false);

  useEffect(() => {
    const fbUrl = data?.pageUrl;
    if (isAndroid) {
      setNewFbUrl(fbUrl.replace("www.", ""));
    } else if (isIOS) {
      setNewFbUrl(`fb://profile/${data?.page_id}`);
    } else {
      setNewFbUrl(fbUrl);
    }
  }, [data?.fb_id, data?.pageUrl]);

  const defaultUrl = window.location.href;

  const isChrome = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isIOSChrome = /crios/.test(userAgent);
    const isRegularChrome = /chrome/.test(userAgent) && /google inc/.test(navigator.vendor);

    return isIOSChrome || isRegularChrome;
  };

  useEffect(() => {
    setIsChromeBrowser(isChrome());
  }, []);

  function redirectToFb(){
    const facebookPageURL = data?.pageUrl;
    const facebookAppURL = `fb://profile/${data?.page_id}`;

    var start = new Date().getTime();

    const timeout = setTimeout(function() {

      if(isChromeBrowser){
        window.location.href = facebookPageURL;
      }else{
        var end = new Date().getTime();

        if (end - start < 1505) {
          window.location.href = facebookPageURL;
        }
      }

    }, 1500);

    window.top.location.href = data?.page_id ? facebookAppURL : facebookPageURL;
  
    window.addEventListener('blur', function() {
      clearTimeout(timeout);
    });
  }

  useEffect(() =>{
    const handleWindowVisibility = () => {
      window.location.href = defaultUrl;
    };

    if (isIOS && data?.live != true){
      window.addEventListener('visibilitychange', handleWindowVisibility);
    }

    return ()=>window.removeEventListener('visibilitychange', handleWindowVisibility);
  },[isIOS,data?.live]);


  const handleClick = (e) => {
    if (isIOS) {
      redirectToFb();
    } else if (isAndroid) {

      window.location = data?.page_id ? `fb://page/${data.page_id}` : data?.pageUrl;

      const androidUrlTimeout = setTimeout(function() {
        window.location.href = data?.pageUrl;
      }, 1500);

      window.addEventListener('blur', function() {
        clearTimeout(androidUrlTimeout);
      });

    } else {
      window.open(data?.pageUrl, "_blank");
    }
  };

  useEffect(() => {
    showWelcomeScreen(data?.screen ? true : false);
  }, [data?.screen]);

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });

    const d = setTimeout(() => {
      showWelcomeScreen(false);
      data.screen = false;
    }, 3000);

    return () => clearTimeout(d);
  }, [data]);

  const light = /^#[0-9a-fA-F]{6}$/.test(data.cover)
    ? isLight(data.cover)
    : false;

  useScroller(data);

  return welcomeScreen ? (
    <WelcomeScreen screen={data?.screen} />
  ) : (
    <div className="flex flex-col justify-center items-center relative">
      <div className="flex flex-col items-center justify-center w-full max-w-[600px] relative">
        <div className="flex items-center w-full">
          {data?.cover ? (
            <>
              {/^#[0-9a-fA-F]{6}$/.test(data.cover) ? (
                <div
                  className="w-full xxs:h-[170px] xs:h-[184px] sm:h-[370px] duration-500"
                  style={{ backgroundColor: data.cover }}
                />
              ) : (
                <img src={data.cover} className="w-full max-h-[350px] object-cover" alt="cover" />
              )}
            </>
          ) : (
            <img
              src="/images/images/new/v2/fb_cover.png"
              className="w-full"
              alt=""
            />
          )}
          <div
            className={`absolute top-10 xsm:top-12 sm:top-20 right-4 w-[38px] h-[38px] xsm:w-[48px] xsm:h-[48px] ${
              light ? "bg-black/[.15]" : "bg-white/[.15]"
            } rounded-[4px] mb-[18px] xs:mb-[25px] border-[1px] ${
              light ? "border-black/[1]" : "border-white/[1]"
            } cursor-pointer flex items-center justify-center duration-500`}
            onClick={() => ShareOrCopy(data)}
          >
            <svg
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.63118 9.43441C7.05656 8.2874 5.87023 7.5 4.5 7.5C2.567 7.5 1 9.067 1 11C1 12.933 2.567 14.5 4.5 14.5C5.87023 14.5 7.05656 13.7126 7.63118 12.5656M7.63118 9.43441C7.86718 9.90549 8 10.4372 8 11C8 11.5628 7.86718 12.0945 7.63118 12.5656M7.63118 9.43441L14.3688 6.06559M7.63118 12.5656L14.3688 15.9344M14.3688 6.06559C14.9434 7.2126 16.1298 8 17.5 8C19.433 8 21 6.433 21 4.5C21 2.567 19.433 1 17.5 1C15.567 1 14 2.567 14 4.5C14 5.06276 14.1328 5.59451 14.3688 6.06559ZM14.3688 15.9344C14.1328 16.4055 14 16.9372 14 17.5C14 19.433 15.567 21 17.5 21C19.433 21 21 19.433 21 17.5C21 15.567 19.433 14 17.5 14C16.1298 14 14.9434 14.7874 14.3688 15.9344Z"
                stroke={light ? "black" : "white"}
                strokeWidth="1.5"
                strokeLinecap="round"
                className="duration-500"
              />
            </svg>
          </div>
        </div>
        <div className="flex items-start w-full px-[20px] xxxs:px-[10px] xxs:px-[15px] -mt-[50px] xxs:-mt-[60px] xs:-mt-[75px] sm:-mt-[110px]">
          {data?.profileImg ? (
            <div
              style={{
                backgroundImage: `url(${data.profileImg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
              className="w-[100px] xxs:w-[100px] xs:w-[150px] sm:w-[220px] h-[100px]  xxs:h-[100px] xs:h-[150px] sm:h-[220px] rounded-full"
              alt=""
            />
          ) : (
            <img
              src="/images/images/new/fb_pic.webp"
              className="w-[100px] xxs:w-[120px] xs:w-[150px] sm:w-[220px] rounded-full"
              alt=""
            />
          )}
        </div>
        {data?.title || data?.description ? (
          <div className="flex flex-col w-full px-[20px] xxxs:px-[10px] xxs:px-[15px] mt-6 mb-10 xxs:mt-4 xxs:mb-4">
            {data?.title? (
              <p className="text-black facebook-title font-bold text-start xxxs:text-[13px] text-base xxs:text-[15px] xs:text-2xl sm:text-3xl mb-3 text-pretty break-words w-full" style={{fontFamily: `'${data?.font_title}'`}}>{data?.title}</p>
            ):(
              <></>
            )}
            {data?.description ? (
              <p
                className=" text-[#767C83] xxxs:text-[11px] text-start text-xs xxs:text-[13px] xs:text-base facebook-description text-pretty break-words w-full"
                style={{ fontFamily: `'${data?.font_text}'` }}
              >
                {data?.description}
              </p>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <div className="my-3"></div>
        )}
        {data?.pageUrl ? (
          <div className="flex flex-col w-full px-[20px] xxxs:px-[10px] xxs:px-[15px] space-y-[12px] mb-5">
            {data.pageUrl === "#" ? (
              <div className="flex items-center justify-center py-4 xxs:py-3 px-3 xxs:px-2 rounded-[4px] space-x-[4px] bg-[#0866FF] cursor-pointer">
                <p
                  className="text-white font-medium text-[10px] xxs:text-sm xs:text-base sm:text-lg truncate"
                  style={{ fontFamily: `'${data?.font_title}'` }}
                >
                  {t("facebook.button")}
                </p>
              </div>
            ):(
              <a
                {...(data?.live === true ? {href: newFbUrl} : {})}
                id="facebook_btn"
                target="_blank"
                className="hover:cursor-pointer"
                rel="noreferrer"
                {...(data?.live !== true ? { onClick: handleClick, onTouchStart: handleClick } : {})}
                >
                <div className="flex items-center justify-center py-4 px-3 xxs:px-2 xxs:py-3 rounded-[4px] space-x-[4px] bg-[#0866FF] cursor-pointer">
                <p className="text-white font-medium text-[10px] xxs:text-sm xs:text-base sm:text-lg truncate" style={{fontFamily: `'${data?.font_title}'`}}>{t('facebook.button')}</p>
              </div>
              </a>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default FacebookQci;

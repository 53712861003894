import FileSaver from "file-saver";
import nullCheck from "./nullChecker";
import { convertImageToBase64 } from "./utils";
import VCard from "vcard-creator";

const saveVCard = (data) => {
    if(!data || data?.live){
        return;
    }

    const myVCard = new VCard("vcard");
    const lastName = nullCheck(data, 'vcard_last_name');
    const firstName = nullCheck(data, 'vcard_first_name');
    const offer_number = nullCheck(data, 'offer_number');
    const offer_street = nullCheck(data, 'offer_street');
    const street = data?.street_number ? `${offer_number} ${offer_street}` : `${offer_street} ${offer_number}`;
    const offer_city = nullCheck(data, 'offer_city');
    const offer_state = nullCheck(data, 'offer_state');
    const offer_postalCode = nullCheck(data, 'offer_postalcode');
    const offer_country = nullCheck(data, 'offer_country');

    convertImageToBase64(data, (imageData) => {
        myVCard
        .addName(lastName, firstName)
        .addPhoto(removeMimeType(imageData))
        .addCompany(data?.vcard_company)
        .addJobtitle(data?.vcard_profession)
        .addNote(data?.vcard_note)
        .addLogoURL(data?.images)
        .addAddress(undefined, undefined, street, offer_city, offer_state, offer_postalCode, offer_country, 'HOME;WORK');

        data?.vcard_website.forEach((e, i) => { myVCard.addURL(e.website, e.title); });
        data?.vcard_phone.forEach((e, i) => { myVCard.addPhoneNumber(e.number, getPhoneNumberType(e.type)); });
        data?.vcard_email.forEach((e, i) => { myVCard.addEmail(e.email, e.title); });

        const blob = new Blob([myVCard.toString()], {
            type: "text/vcard;charset=utf-8",
        });
        FileSaver.saveAs(blob, `${firstName}_${lastName}.vcf`);
    });
};

const removeMimeType = (imageData)=> imageData.replace('data:image/jpeg;base64,', '');

const getPhoneNumberType = (type) => {

    switch (type.toLowerCase()) {
        case 'fax':
            return 'TYPE=WORK,FAX';
        case 'mobile-phone':
        case 'other':
            return 'TYPE=CELL';
        case 'home':
            return 'TYPE=HOME';
        case 'work':
            return 'TYPE=WORK';
        default:
            return 'TYPE=CELL';
    }
};

export default saveVCard;